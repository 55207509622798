import React from 'react'
import $ from "jquery"
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';
window.$ = $
require('../spritespin')

class SpriteSpin extends React.Component {

    constructor(props) {
        super(props);
        this.el = null;
        this.isMoved = false;
        this.body = $('.container,.modal')
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let self = this
        let isMoved = true;
        if (nextProps.contentId === nextProps.currentContent) {
            const clientWidth = document.activeElement.clientWidth>550?550:document.activeElement.clientWidth;
            const sizeShared = isMobile?clientWidth:550
            const options = {
                source: self.props.frames,
                height:$('.container.shared__item').length?sizeShared:412,
                width: $('.container.shared__item').length?sizeShared:412,
                frameTime: 72,
                sense: -1,
                zoomUseWheel: false,
                zoomUseClick: false,
                sizeMode: 'fit',
                plugins: [
                    '360',  // display plugin
                    'drag', // interaction plugin
                    'zoom'  // zoom plugin
                ],
                onLoad: function (e, spin) {
                    if (!isMobile) {
                        spin.target.append('<div class="spin__controls">' +
                            '<span id="prev_slide" class="spin__action"><i class="fa fa-backward"></i></span>' +
                            '<span id="next_slide" class="spin__action"><i class="fa fa-forward"></i></span>' +
                            '<span id="play_spin" class="spin__action"><i class="fa fa-pause"></i></span>' +
                            '<span id="zoom_spin" class="spin__action"><i class="fa fa-search"></i></span>' +
                            '</div>');
                    }
                },
                onProgress: function (e, spin) {
                    self.props.onSpinProgress(spin.progress.percent)
                },
                onInit: function (e, spin) {
                    let cvStyle = spin.canvas[0].style;
                    cvStyle.backgroundImage = 'url(' + (self.props.frames.length ? self.props.frames[0] : "") + ')';
                    cvStyle.backgroundRepeat = 'no-repeat';
                    cvStyle.backgroundSize = '100%';
                    cvStyle.backgroundPosition = 'center';
                    cvStyle.display = 'block';
                }
            }
            this.el.spritespin(options);
            this.api = this.el.spritespin("api");
            this.data = this.el.spritespin("data");
            this.body.on('click.spin', '#prev_slide',function(e) {
                $('.spin__controls #play_spin i').removeClass('fa-pause').addClass('fa-play')
                self.api.stopAnimation()
                self.api.prevFrame()
                isMoved = false;
            }).on('click.spin', '#next_slide',function() {
                $('.spin__controls #play_spin i').removeClass('fa-pause').addClass('fa-play')
                self.api.stopAnimation()
                self.api.nextFrame()
                isMoved = false;
            }).on('click.spin', '#play_spin',function(e) {
                if ( $('.spin__controls #play_spin i').hasClass('fa-pause')) {
                    $('.spin__controls #play_spin i').removeClass('fa-pause').addClass('fa-play');
                    self.api.stopAnimation()
                    isMoved = false;
                } else {
                    $('.spin__controls #play_spin i').addClass('fa-pause').removeClass('fa-play');
                    self.api.startAnimation()
                    isMoved = true;
                }
            }).on('click.spin', '#zoom_spin',function(e) {
                e.preventDefault();
                $('.zoom-stage').css({
                    top: "83%",
                    left:  "49%",
                })
                $('.image-gallery-content').on('mousemove','.spritespin-instance.with-canvas',function (e){
                    $('.zoom-stage').css({
                        left:  Math.floor((window.x-0.15)*100)+"%",
                        top:  Math.floor((window.y-0.15)*100) +"%",
                    })
                }).on('touchmove','.spritespin-instance.with-canvas',function (e){
                    $('.zoom-stage').css({
                        left:  Math.floor((window.x-0.15)*100)+"%",
                        top:  Math.floor((window.y-0.15)*100) +"%",
                    })
                })
                self.api.toggleZoom();
            }).on('dblclick.spin', this.el,function(e) {
                return false;
            });
        }
    }
    getSize(){
        const isShared = $('.container.shared__item').length;
        if(isShared) {
            if(isMobile) {
                return document.documentElement.clientWidth
            } else {
                return 550
            }
        } else {
            return 313
        }
    }
    componentWillUnmount() {
        this.el.spritespin('destroy');
        this.body.off('click.spin')
    }

    render() {
        return React.createElement('div', {
            ref: (el) => this.el = window.$(el)
        });
    }
}

function mapStateToProps(state) {
    const {itemDetails} = state
    return {
        currentContent:itemDetails.props?itemDetails.props.currentContent:null
    }
}

export default connect(mapStateToProps)(SpriteSpin);