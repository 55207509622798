import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {updateItemsList} from "../actions/itemActions";
import ItemsManager from "./ItemsManager";
import store from "../store";
import ApiData from "./ApiData";
import {hide, show} from "redux-modal";
import {connect} from "react-redux";
import {updateUserData} from "../actions/MainActions";
import {createBrowserHistory} from "history";
import {generateUrlData,SKIP_PARAMS} from "../helpers";

const history = createBrowserHistory();

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.apiData = new ApiData()
    }

    componentDidMount() {
        this.setState({
            items: [],
            jewelry_id: null,
            stone_id: null,
            metal_id: null,
            sku_id: null,
            page: 1,
            hasMore: true,
            clearOld: true,
            stopChecking: true,
            getNewItems: true
        });
    }

    componentWillReceiveProps = (props) => {
        const {userData, items} = props

        this.setState({
            keyword: userData.keyword,
            gallery_uid: userData.gallery_uid,
            page: userData.page,
            password: userData.password,
            stopChecking: userData.stopChecking,
            passwordChecked: userData.passwordChecked,
            clearOld: userData.clearOld,
            init: userData.init,
            filters: userData.filters,
            getNewItems: userData.getNewItems ? userData.getNewItems : false,
            reqParams: userData.reqParams,
            hasMore:userData.hasMore
        }, function () {
            if (this.state.getNewItems) {
                this.getItems()
            }
        });

    }

    getReqParams() {
        let reqParams = this.state.reqParams ? this.state.reqParams : {};
        if (this.state.keyword != undefined) {
            reqParams.keyword = this.state.keyword;
        } else {
            reqParams.guid = this.state.gallery_uid;
        }
        if (this.state.reqParams) {
            reqParams = {...reqParams, ...this.state.reqParams}
        }
        reqParams.page = this.state.clearOld || this.state.init ? 1 : this.state.page + 1;
        reqParams.password = this.state.password;
        return reqParams;
    }

    getItems = async () => {
        let reqParams = this.getReqParams();
        if (this.state.keyword != undefined) {
            reqParams.keyword = this.state.keyword;
            reqParams.new_catalog = 1;
        } else {
            reqParams.guid = this.state.gallery_uid;
        }
        reqParams.page = this.state.clearOld || this.state.init ? 1 : this.state.page + 1;
        reqParams.password = this.state.password;
        reqParams = {...reqParams, ...generateUrlData(this.state.filters)}
        let search = "";
        Object.entries(reqParams).map((v) => {

            if (v[0] != 'gallery_uid' && v[0] !== 'new_catalog' && SKIP_PARAMS.indexOf(v[0]) === -1 && v[1]&& v[1].length) {
                search += v[0] + "=" + v[1] + "&"
            } else if(v[0] === 'f') {
                search+= v[0]
            }
        })
        console.log(2,search)
        history.push({
            pathname: window.location.pathname,
            search: search
        });
        if (!this.state.stopChecking) {
            const response = await this.apiData.getItems(reqParams);
            const {password, hasMore, user_id,total} = response
            if (password) {
                this.setState({requestPassword: true})
                this.props.show('PasswordModal');
                store.dispatch(updateUserData({
                    wrongPassword: this.state.passwordChecked ? true : false,
                    stopChecking: true,
                }))
            } else {
                this.props.hide('PasswordModal');
                console.log(response)
                store.dispatch(updateItemsList(response, this.state.clearOld));
                store.dispatch(updateUserData({
                    total:response.total,
                    getNewItems:false,
                    init: false,
                    clearOld: false,
                }))
                this.setState({
                    total   : response.total,
                    page: reqParams.page,
                    hasMore: hasMore,
                    user_id: user_id,
                    init: false,
                    clearOld: false,
                    getNewItems: false,
                });
            }
        }
    };

    render() {
        if (store.getState().items && this.state) {
            return (
                <div className="gallery">
                    {this.state && <InfiniteScroll
                        dataLength={store.getState().items.length}
                        next={this.getItems}
                        hasMore={this.state.hasMore}
                        loader={<div className="row">
                            <div className="col-md-12 ta-c">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>}
                        style={{'overflow': 'inherit'}}
                    >
                        <div className="row">
                            <ItemsManager/>
                        </div>
                    </InfiniteScroll>
                    }
                </div>
            );
        } else {
            if (this.props.is_owner) {
                return (
                    <div className="gallery_h4"><h4>Gallery temporarily unavailable. Please update your
                        subscription </h4></div>
                )
            } else {
                if (this.state && this.state.requestPassword) {
                    return (
                        <div className="gallery_h4"><h4>Enter password to view gallery.</h4></div>
                    )
                }

                return (
                    <>
                        <div className="gallery-loading">
                            <svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 35 40">
                                <path fill="#00CAC4" fillRule="evenodd"
                                      d="M17.861.05l16.96 9.908c.11.065.179.184.179.312V29.73a.362.362 0 0 1-.18.312L17.86 39.95a.362.362 0 0 1-.36.003l-5.593-3.15v-6.865l5.596 3.151c.11.063.245.063.355 0l11.184-6.3a.362.362 0 0 0 .185-.315v-12.59a.362.362 0 0 0-.177-.312L17.863 6.919a.362.362 0 0 0-.366-.002L5.954 13.572a.362.362 0 0 0-.18.313V33.19L0 29.937V10.273c0-.13.07-.25.182-.315L17.5.05a.362.362 0 0 1 .362.002zm-1.083 13.445l6.856 6.866-6.856 6.865-2.886-2.168v-9.033l2.886-2.53z"></path>
                            </svg>
                        </div>

                        <div className="gallery-loading">
                            <div className="loading-spinner">
                                <div className="csspinner processing"></div>
                            </div>
                        </div>
                    </>
                )
            }
        }

    }
}

function mapStateToProps(state) {
    const {userData, items} = state
    return {
        userData: userData,
    }
}

export default connect(mapStateToProps, {show, hide})(Gallery)